import { formatDecimal, isPresent } from '@luminovo/commons';
import { colorSystem, FieldSelect, Flexbox, Text, WarningCircleIcon } from '@luminovo/design-system';
import { AwardScenarioDTO } from '@luminovo/http-client';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { useAwardScenarios, useNegotiationLineItems } from '../hooks/negotiationHandlers';
import { formatAwardScenario, isAutomaticAwardScenario } from '../model/awardScenario';
import { countNegotiationLineItemsWithoutOffers } from '../model/countNegotiationLineItemsWithoutOffers';

export function FieldGlobalAwardScenario({
    type,
    negotiationId,
    showNoOfMissingLineItems = false,
    disabled = false,
    onChange,
}: {
    type: 'referenceScenario' | 'selectedScenario';
    negotiationId: number;
    showNoOfMissingLineItems?: boolean;
    disabled?: boolean;
    onChange?: (scenario: AwardScenarioDTO) => void;
}) {
    const { referenceScenario, selectedScenario, setReferenceScenario, setSelectedScenario } = useNegotiationsContext();

    const scenario = type === 'referenceScenario' ? referenceScenario : selectedScenario;
    const setScenario = type === 'referenceScenario' ? setReferenceScenario : setSelectedScenario;

    if (!isPresent(scenario) || !isPresent(scenario)) {
        return null;
    }

    return (
        <FieldSelectAwardScenario
            showNoOfMissingLineItems={showNoOfMissingLineItems}
            negotiationId={negotiationId}
            disabled={disabled}
            value={scenario}
            onChange={(val) => {
                setScenario(val);
                onChange && onChange(val);
            }}
        />
    );
}
export function FieldSelectAwardScenario({
    negotiationId,
    onChange,
    value,
    showNoOfMissingLineItems: showWarning = false,
    disabled = false,
}: {
    negotiationId: number;
    onChange: (value: AwardScenarioDTO) => void;
    value: AwardScenarioDTO;
    showNoOfMissingLineItems?: boolean;
    disabled?: boolean;
}) {
    const { data: awardScenarios = [] } = useAwardScenarios(negotiationId);
    const { data: lineItems = [] } = useNegotiationLineItems(negotiationId);
    const hasNoOfferLength = countNegotiationLineItemsWithoutOffers(lineItems, value);

    return (
        <>
            <FieldSelect
                style={{ minWidth: 260 }}
                onChange={(awardScenario) => {
                    if (isPresent(awardScenario)) {
                        requestAnimationFrame(() => onChange(awardScenario));
                    }
                }}
                options={awardScenarios}
                disabled={disabled}
                value={value}
                renderOption={(awardScenario) => {
                    return (
                        <Flexbox sx={{ justifyContent: 'space-between', width: '100%', gap: 2 }}>
                            <Text showEllipsis>{formatAwardScenario(awardScenario)}</Text>
                            <Text>{formatDecimal(awardScenario?.awarded_offers.length)}</Text>
                        </Flexbox>
                    );
                }}
                disableClearable={true}
                groupBy={(item) => (isAutomaticAwardScenario(item) ? 'Automatic' : 'Manual')}
                isOptionEqualToValue={(awardScenario, value) => awardScenario?.id === value?.id}
                getOptionKey={(awardScenario) => awardScenario?.id.toString()}
                getOptionLabel={formatAwardScenario}
            />
            {showWarning && hasNoOfferLength > 0 && (
                <Flexbox alignItems={'center'} gap={2}>
                    <WarningCircleIcon fontSize="small" />
                    <Text variant="caption" color={colorSystem.yellow[8]}>
                        {hasNoOfferLength} line items without offer
                    </Text>
                </Flexbox>
            )}
        </>
    );
}
