/* eslint-disable camelcase */
import { extractFileNameFromUrl } from '@luminovo/commons';
import {
    PriceBreakInputDTO,
    QuantityUnitDTO,
    StandardPartOfferDTO,
    StandardPartOfferInputDTO,
    StandardPartOfferUpdateDTO,
} from '@luminovo/http-client';
import { convertValidForToCustomer, convertValidForToRfqId, parseSpn } from '@luminovo/sourcing-core';
import { AttachmentFile } from '../../../resources/http/useHttpFileUpload';
import { undefinedToNull } from '../../../utils/typingUtils';
import { PriceBreakInput, StandardPartOfferFormValues } from './StandardPartOfferForm';

function convertToPriceBreakInputDTO(priceBreaks: PriceBreakInput[]): PriceBreakInputDTO[] {
    return priceBreaks.map((priceBreak) => {
        return {
            moq: priceBreak.moq,
            mpq: priceBreak.mpq,
            unit_price: priceBreak.unitPrice,
        };
    });
}

function createUnitOfMeasurement(values: StandardPartOfferFormValues): QuantityUnitDTO {
    return { unit: values.quantityUnit, quantity: values.quantity };
}

export function convertPriceBreaks(
    priceBreaks: StandardPartOfferDTO['available_prices']['price_breaks'],
): StandardPartOfferFormValues['priceBreaks'] {
    return priceBreaks.map((priceBreak) => {
        return {
            moq: priceBreak.moq,
            mpq: priceBreak.mpq,
            unitPrice: parseFloat(priceBreak.unit_price.amount),
        };
    });
}

export function convertToStandardPartOfferUpdateDTO(values: StandardPartOfferFormValues): StandardPartOfferUpdateDTO {
    return {
        supplier_part_number: parseSpn(values.supplierPartNumber),
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        price_type: values.priceType,
        customer: values.customerId && convertValidForToCustomer(values.validFor, values.customerId),
        rfq: values.rfqId && convertValidForToRfqId(values.validFor, values.rfqId),
        notes: undefinedToNull(values.notes),
        valid_until: undefinedToNull(values.validUntilDate),
        currency: values.currency,
        availability_input: {
            stock: undefinedToNull(values.stock),
            factory_lead_time_days: undefinedToNull(values.factoryLeadTime),
            factory_quantity: undefinedToNull(values.factoryQuantity),
            on_order: values.onOrder.map((onOrder) => {
                return {
                    quantity: onOrder.quantity,
                    date: undefinedToNull(onOrder.date),
                };
            }),
        },
        price_break_inputs: convertToPriceBreakInputDTO(values.priceBreaks),
        packaging: undefinedToNull(values.packaging),
        unit_of_measurement: createUnitOfMeasurement(values),
        offer_number: undefinedToNull(values.offerNumber),
        ncnr: undefinedToNull(values.ncnr),
    };
}

export function convertToStandardPartOfferInputDTO(values: StandardPartOfferFormValues): StandardPartOfferInputDTO {
    return {
        supplier_part_number: parseSpn(values.supplierPartNumber),
        linked_part: values.linkedPart,
        supplier_and_stock_location: values.supplierAndStockLocation.id,
        price_type: values.priceType,
        customer: values.customerId && convertValidForToCustomer(values.validFor, values.customerId),
        rfq: values.rfqId && convertValidForToRfqId(values.validFor, values.rfqId),
        notes: undefinedToNull(values.notes),
        valid_until: undefinedToNull(values.validUntilDate),
        valid_from: undefinedToNull(values.validFromDate),
        item_class: undefinedToNull(values.itemClass),
        cancellation_window_in_days: undefinedToNull(values.cancellationWindow),
        currency: values.currency,
        availability_input: {
            stock: undefinedToNull(values.stock),
            factory_lead_time_days: undefinedToNull(values.factoryLeadTime),
            factory_quantity: undefinedToNull(values.factoryQuantity),
            on_order: values.onOrder,
        },
        price_break_inputs: convertToPriceBreakInputDTO(values.priceBreaks),
        packaging: undefinedToNull(values.packaging),
        unit_of_measurement: createUnitOfMeasurement(values),
        offer_number: undefinedToNull(values.offerNumber),
        ncnr: undefinedToNull(values.ncnr),
    };
}

export function convertAzureFilesToUploadFiles(files: string[]): AttachmentFile[] {
    return files.map((file) => ({
        file: null,
        state: 'StoredInAzure',
        name: extractFileNameFromUrl(file),
        url: file,
    }));
}
