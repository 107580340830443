/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatDecimal, formatMonetaryValue, isPresent, transEnum } from '@luminovo/commons';
import { createColumnHelper, Tag, TanStackTable, Tooltip, useTanStackTable } from '@luminovo/design-system';
import { QuoteRequestLineItemDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import React from 'react';
import { useOTSOffers } from '../../../resources/offer/offerHandler';
import { useOfferDrawer } from '../../SolutionManager/components/OfferDrawer';
import { useNegotiationsContext } from '../context/NegotiationsContext';
import { compareQuoteRequestLineItems } from '../model/compareQuoteRequestLineItem';
import { extractAwaredOfferFromScenario } from '../model/extractors/extractorNegotionsLineItem';
import { formatNegotiationPart } from '../model/formatNegotiationPart';
import { quoteRequestLineItemStatusTranslations } from '../model/getQuoteRequestLineItemStatus';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';
import { NegotiationPartLabel } from './NegotiationPartLabel';
import { QuoteRequestLineItemStatusChip } from './QuoteRequestLineItemStatusChip';
import { Header } from './TableHeader';

type RowData = QuoteRequestLineItemDTO & {
    offer: StandardPartOfferDTO | undefined;
};

const columnHelper = createColumnHelper<RowData>();

const columns = [
    columnHelper.text('component_origin', {
        id: 'ipn',
        label: () => t`IPN`,
        size: 150,
        cell: ({ row }) => {
            return <NegotiationIpnLabel ipn={row.original.component_origin} />;
        },
    }),
    columnHelper.text((row) => formatNegotiationPart(row.requested_part), {
        id: 'requestedPart',
        label: () => t`Requested part`,
        size: 240,
        cell: ({ row }) => <NegotiationPartLabel part={row.original.requested_part} />,
    }),
    columnHelper.text((row) => row.description ?? '-', {
        id: 'description',
        label: () => t`Description`,
        size: 200,
    }),
    columnHelper.number((row) => formatQuantity(row.required_quantity, { showPiecesUnit: false }), {
        id: 'requiredQuantity',
        label: () => t`Qty`,
        size: 100,
    }),
    columnHelper.number((row) => formatQuantity(row.potential_quantity ?? 0, { showPiecesUnit: false }), {
        id: 'potentialQuantity',
        size: 100,
        label: () => t`Potential Qty`,
    }),
    columnHelper.monetaryValue(() => null, {
        id: 'referenceUnitPrice',
        label: () => t`Reference unit price`,
        header: () => <Header title={t`Unit price`} subtitle={t`Reference`} />,
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        cell: function Cell({ row }) {
            const { referenceScenario } = useNegotiationsContext();

            if (!isPresent(referenceScenario)) {
                return '-';
            }

            const referenceUnitPrice = extractAwaredOfferFromScenario(
                row.original.negotiation_line_item_id,
                referenceScenario,
            )?.awarded_solution.unit_price;
            return formatMonetaryValue(referenceUnitPrice, 'unit-price', { ifAbsent: '-' });
        },
    }),
    columnHelper.monetaryValue('target_price', {
        id: 'targetPrice',
        label: () => t`Target price`,
        size: 100,
        cell: (item) => formatMonetaryValue(item.getValue(), 'unit-price', { ifAbsent: '-' }),
    }),
    columnHelper.text((row) => formatNegotiationPart(row.received_offer?.part), {
        id: 'offeredPart',
        label: () => t`Offered part`,
        size: 200,
        cell: ({ row }) => <NegotiationPartLabel part={row.original.received_offer?.part} />,
    }),
    columnHelper.monetaryValue((row) => row.received_offer?.unit_price, {
        id: 'unitPrice',
        label: () => t`Unit price`,
        size: 100,
        renderType: 'generic',
        cell: ({ getValue }) => formatMonetaryValue(getValue(), 'unit-price', { ifAbsent: '-' }),
    }),
    columnHelper.number((row) => row.received_offer?.moq, {
        id: 'moq',
        label: () => t`MOQ`,
        size: 100,
        cell: ({ row, getValue }) => {
            // warn if the MOQ is 50% or more of the required quantity
            const moq = getValue();
            if (!isPresent(moq)) {
                return <>-</>;
            }
            const requiredQuantity = row.original.required_quantity.quantity;
            const showWarning = moq > requiredQuantity * 0.5;
            if (showWarning) {
                return (
                    <Tooltip
                        title={`Large MOQ: ${formatDecimal(moq / requiredQuantity, { maximumFractionDigits: 1 })}x the requested quantity`}
                    >
                        <span>
                            <Tag attention="low" color="yellow" label={formatDecimal(getValue(), { ifAbsent: '-' })} />
                        </span>
                    </Tooltip>
                );
            }
            return <>{formatDecimal(getValue(), { ifAbsent: '-' })}</>;
        },
    }),
    columnHelper.number((row) => row.received_offer?.mpq, {
        id: 'mpq',
        label: () => t`MPQ`,
        size: 100,
        cell: ({ getValue }) => {
            return <>{formatDecimal(getValue(), { ifAbsent: '-' })}</>;
        },
    }),

    columnHelper.number((row) => row.offer?.available_prices.stock, {
        id: 'stock',
        label: () => t`Stock`,
        size: 100,
        cell: ({ getValue }) => {
            return <>{formatDecimal(getValue(), { ifAbsent: '-' })}</>;
        },
    }),

    columnHelper.text((row) => row.offer?.packaging, {
        id: 'packaging',
        label: () => t`Packaging`,
        size: 100,
        cell: ({ getValue }) => {
            return <>{getValue() ?? '-'}</>;
        },
    }),

    columnHelper.text((row) => row.offer?.ncnr, {
        id: 'ncnr',
        label: () => t`NCNR`,
        size: 100,
        cell: ({ getValue }) => {
            return <>{getValue() ?? '-'}</>;
        },
    }),

    columnHelper.text((row) => row.offer?.notes, {
        id: 'notes',
        label: () => t`Notes`,
        size: 100,
        cell: ({ getValue }) => {
            return <>{getValue() ?? '-'}</>;
        },
    }),

    columnHelper.enum((row) => row.status, {
        id: 'status',
        label: () => t`Status`,
        size: 100,
        getOptionLabel: (opt) => transEnum(opt, quoteRequestLineItemStatusTranslations),
        initialPinning: 'right',
        cell: (item) => <QuoteRequestLineItemStatusChip quoteRequestLineItemStatus={item.getValue()} />,
    }),
];

export function TableQuoteRequestLineItems({ lineItems }: { lineItems: QuoteRequestLineItemDTO[] | undefined }) {
    const { openOfferDrawer } = useOfferDrawer();
    const offerIds = lineItems?.flatMap((lineItem) => {
        if (!isPresent(lineItem.received_offer) || lineItem.received_offer?.offer_id.kind !== 'OffTheShelf') {
            return [];
        }
        return [lineItem.received_offer.offer_id.id];
    });
    const { data: offers } = useOTSOffers({ offerIds, rfqContext: { type: 'OutsideRfQ' } });

    const data: Array<RowData> | undefined = React.useMemo(() => {
        return lineItems
            ?.map((lineItem) => ({
                ...lineItem,
                offer: offers?.find((offer) => offer.id === lineItem.received_offer?.offer_id.id),
            }))
            .sort(compareQuoteRequestLineItems());
    }, [lineItems, offers]);

    const { table } = useTanStackTable({
        data,
        columns,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        onRowClick: (row) => {
            if (!isPresent(row.original.offer)) {
                return;
            }
            openOfferDrawer({
                offer: row.original.offer,
                rfqContext: { type: 'OutsideRfQ' },
            });
        },
    });

    return <TanStackTable table={table} />;
}
